<template>
    <v-content class="mypage">

        <div class="px-3 mt-5">
            <p class="fontsize13">機種変更・再登録（再インストール）の場合、以前の登録情報を引き継ぐには、会員IDとPINコードが必要です。あらかじめ、下記に表示されている会員IDとPINコードをメモするか、メールにて内容を保存してください。</p>

            <v-divider class="mb-2 mt-10"></v-divider>

            <v-row class="" align="center">
                <v-col cols="3">
                    <p class="fontsize11 ma-0">会員ID</p>
                </v-col>

                <v-col cols="9">
                    <p class="title ma-0">{{this.init.user_id}}</p>
                </v-col>
            </v-row>

            <v-divider class="my-2"></v-divider>

            <v-row class="" align="center">
                <v-col cols="3">
                    <p class="fontsize11 ma-0">PINコード</p>
                </v-col>

                <v-col cols="9">
                    <p class="title ma-0 pincode">{{this.userPincode.exchange_code}}</p>
                </v-col>
            </v-row>

            <v-divider class="mb-12 mt-2"></v-divider>

            <div class="text-center mb-7">
                <v-btn rounded class="maincolor-bg title default_button2" x-large :href="mailto">メールで送信</v-btn>
            </div>

        </div>

    </v-content>
</template>

<script>
export default {
  data: () => ({
    init: [],
    subject: '引き継ぎ情報',
    mailto: '',
    userPincode: {
      id: null,
      exchange_code: null
    },
  }),
  async created() {
    this.init = this.storageGet('*');

    let userPincode = await this.apiCall('user/pincode', {params: {
      sid: this.init.sid,
      key: this.init.key,
      device_id: this.init.device_id,
      os: this.init.os,
      login_id: this.init.login_id,
      device_uid: this.init.device_uid
    }});
    if (!userPincode) {
      return false
    }
    this.userPincode = userPincode;
    this.mailto = 'mailto:?subject=' + this.init.shop_name + '　' + this.subject + '&body=' + this.init.shop_name + 'の引き継ぎ情報%0D%0A%0D%0A会員ID%3A ' + this.userPincode.id + '%0D%0APINコード%3A ' + this.userPincode.exchange_code
  },
  methods: {}
};
</script>

<style scoped>
.pincode {
  word-break: break-all;
}
</style>
